/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

.p-column-resizer{
    background-color: rgba(180,180,180,0.1);
    border-right: solid 1px #eee;
}


.p-tabview, .p-tabview-panels, p-tabpanel {
	height: 100%;
	display: flex;
	flex-direction: column;
    flex-grow: 1;
}


body.no-scroll-hack{
    height:100px!important;
    overflow: hidden!important;
    position: fixed;
}

div.p-paginator {
    margin-top: 1rem; 
}

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
/*@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";*/